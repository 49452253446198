/* HOME */

#home-events {
	h2 {
		margin-bottom: 30px;
	}
	.btn {
		margin-top: 35px;
		margin-bottom: 70px;
	}
}

#home-partners {
	padding: 50px 0 40px;
	.d2 {
		margin-bottom: 40px;
	}
	li {
        flex: 1;
		padding: 0 20px;
		img {
			max-width: 100%;
		}
	}
	@include breakpoint(sm) {
		padding: 110px 0 20px;
		h2 {
			margin-bottom: 100px;
		}
	}
}

#home-room {
  .section-padding {
    padding-bottom: 90px;
  }
  .d2 {
    line-height: rem(57);
  }
  a {
    color: $white;
    text-decoration: underline;
    &:hover, &:focus {
      color: darken($white, 20%);
    }
    &:active, &:active:focus {
      color: darken($white, 30%);
    }
  }
  img {
    width: 100%;
    margin-top: -40px;
  }
  .bg-lines-middle {
    &:before, &:after {
      opacity: .2;
    }
  }
  @include breakpoint(sm) {
    .section-padding {
      padding-bottom: 175px;
    }
    img {
      margin-top: -90px;
    }
  }
}
