/* WIDGETS
=========================== */

.nav-pagination {
  transition: all 500ms ease;
  display: inline-block;
  padding-top: 50px;
  &:hover {
    cursor: pointer;
    color: $color-primary;
    &.nav-previous {
      transform: translateX(-3px);
    }
    &.nav-next {
      transform: translateX(3px);
    }
  }
  &.nav-previous {
    margin-right: 10px;
  }
  &.nav-next {
    margin-left: 10px;
  }
  &.text-primary {
    font-weight: bold;
  }
}

.quote-widget {
	padding-top: rem(50);
	padding-left: rem(75);
	border-left: 0;
	position: relative;
	.quote-content {
		font-weight: 300;
		font-style: italic;
		font-size: rem(17);
		line-height: rem(24);
	}
	.quote-footer {
		font-family: $font-title;
		font-weight: 900;
		font-size: rem(14);
		margin-top: rem(30);
	}
	&:before {
		content: "“";
		display: block;
		font-family: $font-fancy;
		font-size: rem(112);
		line-height: rem(112);
		color: $grey;
		position: absolute;
		top: rem(30); left: 0;
	}
	@include breakpoint(md) {
		padding-top: rem(40);
		padding-left: rem(80);
		.quote-content {
			font-size: rem(24);
			line-height: rem(34);
		}
	}
}

.visit-widget {
	min-height: 310px;
	display: block;
	position: relative;
	color: $black;
	overflow: hidden;
	background-color: $black;
    margin-bottom: 30px;
	.visit-image, .fullwidth-bg {
		@include cover-image();
		position: absolute;
		top: 0; right: 0; bottom: 0; left: 0;
		z-index: -1;
		transition: transform 500ms ease;
	}
	.visit-cta {
		position: absolute;
		right: 0; bottom: 0; left: 0;
		background-color: rgba($white, 0.8);
		z-index: 0;
        display: flex;
	}
	.visit-cta-thumb {
		position: relative;
		padding-bottom: 25%;
		width: 25%;
		overflow: hidden;
		.visit-cta-thumb-image {
			@include cover-image();
			position: absolute;
			top: 0; right: 0; bottom: 0; left: 0;
			z-index: -1;
			transition: transform 500ms ease;
		}
		.visit-cta-thumb-icon {
			position: absolute;
			top: 0; right: 0; bottom: 0; left: 0;
			width: 50px; height: 50px;
			margin: auto;
			background-color: $white;
			text-align: center;
			line-height: 52px;
			font-size: 30px;
			border-radius: 30px;
			transition: transform 500ms ease;
			i {
				position: relative;
				left: 2px;
			}
		}
	}
	.visit-cta-summary {
		width: 75%;
		padding: 18px 25px;
	    line-height: 16px;
        display: flex;
    	align-items: center;
		p {
			margin-bottom: 0;
		}
	}
	&:hover {
    color: $black;
		.visit-image, .fullwidth-bg {
			transform: scale(1.1);
			opacity: 0.9;
		}
		.visit-cta-thumb {
			.visit-cta-thumb-image {
				transform: scale(1.1);
			}
			.visit-cta-thumb-icon {
				transform: scale(1.1);
			}
		}
	}
    &:focus{
        color: $black;
    }
	@include breakpoint(xs) {
		.visit-cta-thumb {
			width: 20%;
			padding-bottom: 20%;
		}
		.visit-cta-summary {
			width: 80%;
		}
	}
	@include breakpoint(sm) {
		min-height: 605px;
		.visit-cta-thumb {
			width: 25%;
			padding-bottom: 25%;
		}
		.visit-cta-summary {
	    	line-height: 1.8;
        	display: block;
			width: 75%;
		}
	}
	@include breakpoint(md) {
		min-height: 660px;
        // Max-width fixes hover glitch
        //max-width: 457px;
	}
}

.visit-widget-style-only {
  .visit-image {
    z-index: 1;
    transition: all 500ms ease;
  }
}

.visit-widget-horizontal {
  margin-bottom: 10px;
  min-height: 150px;
  @include breakpoint(sm) {
    min-height: 350px;
  }
  @include breakpoint(md) {
    min-height: 500px;
  }
}

.visit-widget-lg {
  margin-bottom: 0;
  min-height: 250px;
  @include breakpoint(sm) {
    min-height: 500px;
  }
  @include breakpoint(md) {
    min-height: 640px;
  }
}

#map-canvas {
  height: 100%;
  width: 100%;
}

.video-launch-modal {
  margin-top: 35px;
  color: $black;
  display: inline-block;
  height: 245px;
  width: 100%;
  background-color: $grey;
  position: relative;
  .video-lauch-modal-items {
    overflow: hidden;
    height: 100%;
    width: 100%;
    position: relative;
    &::before {
      content: " ";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: $grey;
      opacity: .8;
      z-index: 2;
      transition: opacity 700ms ease;
    }
    .play-btn {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -35px;
      margin-left: -35px;
      transform: scale(1);
      z-index: 4;
      background-color: $white;
      border-radius: 50%;
      width: 70px;
      height: 70px;
      display: flex;
      align-items: center;
      transition: transform 700ms ease;
      i {
        font-size: 45px;
        text-align: center;
        width: 100%;
        margin-left: 5px;
      }
    }
    .video-preview-img {
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      filter: grayscale(100%);
      transform: scale(1);
      transition: all 500ms ease;
    }
  }
  &::after {
    content: " ";
    width: 2px;
    background-color: $black;
    left: 50%;
    transform: translateX(-50%);
    bottom: 50%;
    top: -35px;
    position: absolute;
    z-index: 3;
  }
  &:hover, &:focus {
    color: lighten($black, 20%);
    .video-lauch-modal-items {
      &::before {
        opacity: .4;
      }
      .video-preview-img {
        filter: grayscale(50%);
        transform: scale(1.2);
      }
      .play-btn {
        transform: scale(1.1);
      }
    }
  }
  &:active, &:active:focus {
    color: lighten($black, 20%);
    -webkit-box-shadow: 0px 0px 29px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 29px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 29px 0px rgba(0,0,0,0.2);
    .video-lauch-modal-items {
      &::before {
        opacity: .3;
      }
      .video-preview-img {
        filter: grayscale(40%);
      }
    }
  }
  @include breakpoint(sm) {
    margin-top: 50px;
    height: 365px;
    .video-lauch-modal-items {
      .play-btn {
        margin-top: -45px;
        margin-left: -45px;
        width: 90px;
        height: 90px;
        i {
          font-size: 60px;
          margin-left: 7px;
        }
      }
    }
    &::after {
      top: -50px;
    }
  }
}

.lightbox {
  .lb-container {
    .lb-image {
      border-radius: 0;
      border: none;
    }
  }
}

.lb-data {
  .lb-details {
    display: none;
  }
}

.messages-widget {
  margin: 0;
  padding: 0;
  background-color: $color-primary;
  text-align: center;
  letter-spacing: 0.05em;
  color: $white;
  padding: 15px;
  > li {
    font-size: 12px;
    display: inline-block;
    position: relative;
    padding-left: 30px;
    text-align: left;
    line-height: 14px;
    &:before {
      @include fa-icon('\f00c');
      position: absolute;
      top: 0; bottom: 0; left: 0;
      height: 17px;
      margin: auto;
      font-size: 20px;
    }
  }
}

.fullwidth-img-section{
      background-color: $black;
      height: 341px;
      position: relative;
      @include breakpoint(sm) {
        height: 500px;
      }
      @include breakpoint(md) {
        height: 625px;
      }
}

.custom-img{
    position: relative;
}



.fullwidth-bg{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-position: center center;
    background-size: cover;

}

.filer_image {
    max-width: 100%;
}
