/* RESPONSIVE
===========================
*/
$screen-xxl: 1680px; // Desktop
$screen-xl: 1360px; // HD Laptop
$screen-lg: 1200px; // iPad Landscape, Old Laptop
$screen-md: 992px; // iPad Portrait
$screen-sm: 768px; // Phone landscape
$screen-xs: 480px; // iPhone 6

@mixin breakpoint($class) {
  @if $class == xs {
    @media (min-width: $screen-xs) { @content; }
  }

  @else if $class == sm {
    @media (min-width: $screen-sm) { @content; }
  }

  @else if $class == md {
    @media (min-width: $screen-md) { @content; }
  }

  @else if $class == lg {
    @media (min-width: $screen-lg) { @content; }
  }

  @else if $class == xl {
    @media (min-width: $screen-xl) { @content; }
  }

  @else if $class == xxl {
    @media (min-width: $screen-xxl) { @content; }
  }

  @else {
    @warn "Breakpoint mixin supports: xxs, xs, sm, md, lg, xl, xxl";
  }
}
