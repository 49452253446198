/* POSITIONING
=========================== */

$spacing-sm: 25px;
$spacing-md: 60px;
$spacing-lg: 100px;

/* SPACING
=========================== */

.padding-sm {
    padding-top: $spacing-sm;
    padding-bottom: $spacing-sm;
}

.padding-md {
    padding-top: $spacing-md;
    padding-bottom: $spacing-md;
}

.padding-lg {
    padding-top: $spacing-lg;
    padding-bottom: $spacing-lg;
}

.padding-side-sm {
    padding-left: $spacing-sm;
    padding-right: $spacing-sm;
}

.padding-side-md {
    padding-left: $spacing-md;
    padding-right: $spacing-md;
}

.padding-side-lg {
    padding-left: $spacing-lg;
    padding-right: $spacing-lg;
}

.padding-top-sm { padding-top: $spacing-sm }
.padding-top-md { padding-top: $spacing-md }
.padding-top-lg { padding-top: $spacing-lg }
.padding-bottom-sm { padding-bottom: $spacing-sm }
.padding-bottom-md { padding-bottom: $spacing-md }
.padding-bottom-lg { padding-bottom: $spacing-lg }
.padding-left-sm { padding-left: $spacing-sm }
.padding-left-md { padding-left: $spacing-md }
.padding-left-lg { padding-left: $spacing-lg }
.padding-right-sm { padding-right: $spacing-sm }
.padding-right-md { padding-right: $spacing-md }
.padding-right-lg { padding-right: $spacing-lg }

.margin-sm {
    margin-top: $spacing-sm;
    margin-bottom: $spacing-sm;
}

.margin-md {
    margin-top: $spacing-md;
    margin-bottom: $spacing-md;
}

.margin-lg {
    margin-top: $spacing-lg;
    margin-bottom: $spacing-lg;
}

.margin-side-sm {
    margin-left: $spacing-sm;
    margin-right: $spacing-sm;
}

.margin-side-md {
    margin-left: $spacing-md;
    margin-right: $spacing-md;
}

.margin-side-lg {
    margin-left: $spacing-lg;
    margin-right: $spacing-lg;
}

.margin-top-sm { margin-top: $spacing-sm }
.margin-top-md { margin-top: $spacing-md }
.margin-top-lg { margin-top: $spacing-lg }
.margin-bottom-sm { margin-bottom: $spacing-sm }
.margin-bottom-md { margin-bottom: 35px; @include breakpoint(sm) { margin-bottom: $spacing-md;} }
.margin-bottom-lg { margin-bottom: 50px; @include breakpoint(sm) { margin-bottom: $spacing-lg;} }
.margin-left-sm { margin-left: $spacing-sm }
.margin-left-md { margin-left: $spacing-md }
.margin-left-lg { margin-left: $spacing-lg }
.margin-right-sm { margin-right: $spacing-sm }
.margin-right-md { margin-right: $spacing-md }
.margin-right-lg { margin-right: $spacing-lg }

.mobile-margin-bottom-sm {
  margin-bottom: $spacing-sm;
  @include breakpoint(sm) {
    margin-bottom: 0;
  }
}

.no-margin {
    margin: 0;
}

.section-padding {
    padding-top: 60px;
    padding-bottom: 60px;
    @include breakpoint(sm) {
        padding-top: 110px;
        padding-bottom: 110px;
    }
  &.sp-no-padding-bottom {
    padding-bottom: 0;
  }
}

.bloc-padding-sm {
    padding: 25px;
    @include breakpoint(sm) {
        padding: 45px;
    }
}

.bloc-padding-xs {
    padding: 25px;
    img {
      max-width: 100%;
    }
    @include breakpoint(sm) {
        padding: 35px;
    }
}

/* SEPARATORS
=========================== */

hr {
    margin-top: $spacing-sm;
    margin-bottom: $spacing-sm;
    border: 0;
    border-top: 2px solid $black;
}

/* ALIGNMENT
=========================== */

.vcenter-parent{
    display: table;
    width: 100%;
    height: 100%;
}

.vcenter-middle {
    display: table-cell;
    vertical-align: middle;
}

.vcenter-top {
    display: table-cell;
    vertical-align: top;
}

.vcenter-bottom {
    display: table-cell;
    vertical-align: bottom;
}

/* FIVE COLUMN
=========================== */

.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}

.col-xs-15 {
    width: 20%;
    float: left;
}
@include breakpoint(sm) {
    .col-sm-15 {
        width: 20%;
        float: left;
    }
}
@include breakpoint(md) {
    .col-md-15 {
        width: 20%;
        float: left;
    }
}
@include breakpoint(lg) {
    .col-lg-15 {
        width: 20%;
        float: left;
    }
}

.container-sm {
    max-width: 500px;
    padding-left: 15px;
    padding-right: 15px;
    margin: auto;
}

.container-md {
  max-width: 700px;
  padding-left: 15px;
  padding-right: 15px;
  margin: auto;
  @include breakpoint(lg) {
    max-width: 800px;
  }
}

.row-narrow {
    margin-left: -7px;
    margin-right: -7px;
    [class*="col"] {
        padding-left: 7px;
        padding-right: 7px;
    }
}

.row-no-padding {
  margin: 0;
  [class*="col-"] {
  padding-left: 0 !important;
  padding-right: 0 !important;
  }
}

.flex-row-equal {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    > * {
        flex: 1;
    }
}

.col-no-padding-right {
  @include breakpoint(sm) {
    padding-right: 0;
    a, p {
      width: calc(100% + 15px);
    }
  }
}

.p-no-margin {
  p {
    margin: 0;
  }
}

.p-margin-bottom-sm {
  p {
    margin-bottom: $spacing-sm;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
