/* COLORS
===========================
Important: Always define primary color
*/

$color-primary: #c41230;

$white: #FFF;
$grey-light: #f3f1f0;
$grey: #c5b9b2;
$grey-dark: #0e0e10;
$black: #000;

/* SOCIAL MEDIA */

$facebook-color: #3b5999;
$twitter-color: #55acee;
$linkedin-color: #0077b5;
$youtube-color: #cd201f;
$instagram-color: #e4405f;




/* TEXT COLORS
=========================== */

.text-primary { color: $color-primary !important }
.text-white { color: $white !important }
.text-black { color: $black !important }
.text-grey { color: $grey !important }

/* BACKGROUND COLORS
=========================== */

.bg-primary { background-color: $color-primary !important }
.bg-grey{ background-color: $grey !important; }
.bg-grey-dark{ background-color: $grey-dark !important; }
.bg-grey-light{ background-color: $grey-light !important; }

.bg-lines {
	position: relative;
	overflow: hidden;
	z-index: 1;
	&:before, &:after {
		content: "";
		background-color: $white;
		width: 600px;
		height: 2px;
		position: absolute;
		right: -100px; bottom: 40px;
		z-index: -1;
		display: none;
	}
	&:before {
		transform: rotate(-10deg);
	}
	&:after {
		transform: rotate(-45deg);
	}
	@include breakpoint(sm) {
		&:before, &:after {
			display: block;
		}
	}
}

.bg-lines-middle {
  z-index: 0;
  @extend .bg-lines;
  &:before {
    transform: rotate(-20.5deg);
    width: 919px;
    right: -34px;
    bottom: 133px;
  }
  &:after {
    transform: rotate(-50deg);
    bottom: 215px;
    width: 700px;
    right: -144px;
  }
}

.bg-lines-top {
  z-index: 0;
  @extend .bg-lines;
  &:before {
    transform: rotate(10deg);
    top: 50px;
  }
  &:after {
    transform: rotate(45deg);
    top: 120px;
  }
}
