/* PAGES */
body {
  position: relative;
  overflow: auto;
  &.no-footer {
    footer {
      display: none;
    }
  }
}

.cms-content {
  h1:not([class^=d]) {
    @extend .d1;
  }
  h2:not([class^=d]) {
    @extend .d2;
  }
  h3:not([class^=d]) {
    @extend .d3;
  }
  h4:not([class^=d]) {
    @extend .d4;
  }
  h5:not([class^=d]) {
    @extend .d5;
  }
  h6:not([class^=d]) {
    @extend .d6;
  }
  h1, h2, h3, h4, h5, h6 {
    &:not([class^=d]) {
      margin-bottom: $spacing-sm;
    }
  }
  p {
    margin-bottom: $spacing-sm;
  }
  ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: $spacing-sm;
    li {
      padding-left: 10px;
      position: relative;
      &::before {
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\f105";
        position: absolute;
        left: 0;
        top: 5px;
      }
    }
  }
  a {
    color: $grey-dark;
    text-decoration: underline;
    &:hover, &:focus {
      color: $color-primary;
    }
    &:active, &:active:focus {
      color: darken($color-primary, 20%);
    }
  }
  img {
    max-width: 100%;
      height: auto !important;
    margin-bottom: $spacing-sm;
  }
  iframe {
    max-width: 100%;
  }
}
