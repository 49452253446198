/* CARDS
=========================== */

.card {
  padding: 55px;
}

.card-white {
  background-color: #fff;
}

.card-primary {
  background-color: $color-primary;
}

.grey-card {
  background-color: $grey-light;
  display: block;
  padding: 55px;
  color: $black;
  margin-bottom: 30px;
  .link-action {
    margin-top: 25px;
  }
  @include breakpoint(sm) {
    margin-bottom: 0;
  }
}

.cta-card {
  position: relative;
  transition: all 500ms ease;
  @include breakpoint(sm) {
    padding-bottom: 110px;
    .link-action {
      position: absolute;
      left: 55px;
      bottom: 55px;
      right: 55px;
      i {
        transform: translateX(0);
      }
    }
  }
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px -13px #000;
    color: $black;
    .link-action {
      text-decoration: none;
      color: $black;
      i {
        transform: translateX(10px);
      }
    }
  }
  &:focus {
    color: $black;
    transform: translateY(-10px);
    box-shadow: 0 10px 20px -13px #000;
  }
  &:active {
    color: $black;
    transform: translateY(-10px);
    box-shadow: 0 10px 30px -13px #000;
  }
}

.article-card {
  color: $black;
  margin-bottom: 50px;
  display: block;
  overflow: hidden;
  .article-image {
    @include cover-image();
    background-image: url('../img/placeholder.png');
    background-color: $grey-light;
    padding-bottom: 70%;
    transition: opacity ease 700ms;
  }
  .article-summary {
    transition: transform ease 700ms;
    background-color: $white;
    float: right;
    padding-top: 40px;
    .article-title {

    }
    .article-date {
      font-weight: bold;
      margin-bottom: 20px;
      text-transform: capitalize;
    }
    .article-text {
      margin-bottom: 20px;
    }
  }
  &:hover, &:active, &:focus {
    color: $black;
    .article-image {
      opacity: 0.8;
    }
    .article-summary {
      transform: translateY(-10px);
      i {
        transform: translateX(10px);
      }
    }
  }
  @include breakpoint(xs) {
    .article-summary {
      width: 75%;
      margin-top: -120px;
      padding: 40px;
    }
  }
  @include breakpoint(sm) {
    margin-bottom: 0;
  }
}

.article-col {
  @include breakpoint(sm) {
    &:nth-child(even) .article-card {
      margin-top: 120px;
    }
  }
}

.location-card {
  text-align: center;
  color: $black;
  margin-bottom: 40px;
  display: block;
  .location-square {
    padding-bottom: 50%;
    position: relative;
    overflow: hidden;
    background-color: $black;
  }
  .fullwidth-bg {
    transition: all ease 700ms;
    box-shadow: inset 0 -130px 80px -90px #000;
  }
  .location-summary {
    padding: 15px;
    color: $white;
    background-color: $black;
  }
  .location-title {
  }
  .location-more {
    text-decoration: underline;
    font-size: 12px;
  }
  .location-line {
    position: relative;
    transition: transform ease 500ms;
    margin: 20px 0 10px;
    &:before {
      content: "";
      display: block;
      background-color: $black;
      width: 1px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
    }
  }
  .location-caption {
    max-width: 200px;
    margin: auto;
    transition: transform ease 500ms;
  }
  &:hover {
    color: $black;
    .fullwidth-bg {
      transform: scale(1.1);
      opacity: 0.8;
    }
  }
  @include breakpoint(sm) {
    margin-bottom: 0;
    .location-square {
      padding-bottom: 50%;
    }
    .location-line {
      min-height: 70px;
      margin: 30px 0 20px;
    }
    &:hover {
      .location-line {
        transform: translateY(-10px);
      }
      .location-caption {
        transform: translateY(-5px);
      }
    }
    &.location-card-alt {
      .location-square {
        padding-bottom: 70%;
      }
      .location-line {
        min-height: 140px;
      }
    }
    &.location-card-alt-2 {
      .location-square {
        padding-bottom: 50%;
      }
      .location-line {
        min-height: 50px;
      }
    }
  }
}

.location-card-lg {
  @extend .location-card;
  .location-caption {
    text-align: left;
    max-width: none;
    padding: 20px 30px 0;
  }
  @include breakpoint(sm) {
    .location-caption {
      padding: 0;
      max-width: 350px;
    }
    .location-summary {
      padding-bottom: 35px;
    }
    .location-line {
      min-height: 90px;
    }
    &.location-card-lg-alt {
      .location-line {
        min-height: 165px;
      }
    }
  }
}

.event-card {
  color: $black;
  border-bottom: 2px solid $black;
  display: block;
  padding-bottom: 35px;
  .event-title {
    margin-bottom: 20px;
  }
  .event-description {

  }
  &:after {
    content: "";
    position: absolute;
    right: 0;
    bottom: -2px;
    left: 0;
    height: 3px;
    background-color: $color-primary;
    transform: scaleX(0);
    transition: transform 500ms ease;
  }
  &:hover, &:focus {
    color: $black;
    border-color: transparent;
    &:after {
      transform: scaleX(1);
    }
  }
  & + & {
    margin-top: 30px;
  }
  &.event-card-sm-no-text {
    padding-bottom: 20px;
    .event-title {
      margin-bottom: 0;
      font-size: rem(14);
      font-weight: 900;
    }
    & + & {
      margin-top: 20px;
    }
  }
}

.underline-card-container {
  .underline-card {
    padding: 25px 0;
    border-bottom: 2px solid $black;
    .underline-card-title {
      @extend .d5;
      text-transform: none;
      font-family: $font-content;
      margin-bottom: $spacing-sm;
    }
    .underline-card-text {
      p {
        margin-bottom: $spacing-sm;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  @include breakpoint(sm) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    .underline-card {
      padding: 35px 0;
      width: calc(50% - 15px);
      flex-grow: 1;
      &:nth-child(even) {
        margin-left: 15px;
      }
      &:nth-child(odd) {
        margin-right: 15px;
      }
    }
  }
}

.before-underline-card-container-margin-md {
  margin-bottom: 35px;
}

.card-radio {
  display: inline-block;
  width: 100%;
  color: $black;
  margin-bottom: $spacing-sm;
  .card-radio-img-content {
    height: 140px;
    width: 100%;
    position: relative;
    background-color: $grey;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    &::before {
      content: " ";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: $grey;
      opacity: .8;
      z-index: 2;
      transition: opacity 700ms ease;
    }
    .card-radio-place {
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 3;
      background-color: $white;
      border-radius: 30px;
      padding: 5px 10px;
      width: 135px;
      height: 35px;
      display: flex;
      align-items: center;
      p {
        font-weight: 700;
        font-size: rem(14);
        text-align: center;
        width: 100%;
      }
    }
    .card-radio-img {
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      filter: grayscale(100%);
      transition: filter 700ms ease;
    }
  }
  .card-radio-hidden {
    opacity: 0;
    width: 0;
    height: 0;
    overflow: hidden;
  }
  .card-radio-content {
    background-color: $white;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 10px $spacing-sm;
    .card-radio-radio {
      width: $spacing-sm;
      height: $spacing-sm;
      border-radius: 50%;
      border: 6px solid $grey-light;
      background-color: $grey-light;
      margin-right: $spacing-sm;
      &.checked {
        background-color: $black;
      }
    }
    .card-radio-title {
      font-weight: 700;
      font-size: rem(14);
      text-transform: uppercase;
    }
  }
  &:hover, &:focus {
    color: lighten($black, 20%);
    -webkit-box-shadow: 0px 0px 29px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 29px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 29px 0px rgba(0, 0, 0, 0.1);
    .card-radio-img-content {
      &::before {
        opacity: .4;
      }
      .card-radio-img {
        filter: grayscale(50%);
      }
    }
  }
  &:active, &:active:focus {
    color: lighten($black, 20%);
    -webkit-box-shadow: 0px 0px 29px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 29px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 29px 0px rgba(0, 0, 0, 0.2);
    .card-radio-img-content {
      &::before {
        opacity: .3;
      }
      .card-radio-img {
        filter: grayscale(40%);
      }
    }
  }
  &.active {
    .card-radio-img-content {
      &::before {
        opacity: .3;
      }
      .card-radio-img {
        filter: grayscale(40%);
      }
    }
  }
  @include breakpoint(sm) {
    margin-bottom: 0;
    .card-radio-img-content {
      height: 200px;
    }
  }
}

.card-radio-container {
  margin-bottom: 35px;
  @include breakpoint(sm) {
    margin-bottom: 80px;
  }
}
