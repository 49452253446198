#articles-list {
    padding-bottom: 0;
}

.article-single {
    position: relative;
    padding: 80px 15px;
    background-color: $white;
    .article-single-title {
        margin-bottom: 25px;
    }
    .article-single-date {
        font-size: rem(24);
        font-weight: bold;
        text-transform: capitalize;
        margin-top: 20px;
        margin-bottom: 50px;
        display: inline-block;
    }
    .article-single-body {
        p {
            margin-bottom: 20px;
        }
    }
    @include breakpoint(sm) {
        padding: 100px;
        margin-top: -150px;
    }
    @include breakpoint(md) {
        margin-top: -250px;
    }
    @include breakpoint(lg) {
        margin-top: -300px;
    }
}
