/* HEADER */
header {
  position: absolute;
  width: 100%;
  z-index: 5;
}

.cms-toolbar-expanded {
  .navbar-fixed-top {
    top: 45px;
  }
}

.navbar {
  border-radius: 0;
}

.navbar-default {
  background-color: transparent;
  border: none;
  margin-bottom: 0;
  position: relative;
  .white-bar-container {
    pointer-events: none;
    position: absolute;
    height: 330px;
    width: 290px;
    top: 0;
    left: 0;
    overflow: hidden;
    .nav-white-bar {
      width: 2px;
      height: 500px;
      //background-color: red;
      background-color: rgba(255, 255, 255, 0.2);
      position: absolute;
      &:first-child {
        transform: rotate(23deg);
        top: -20px;
        left: 25px;
      }
      &:last-child {
        transform: rotate(54deg);
        top: -101px;
        left: 60px;
      }
    }
  }
  .navbar-header {
    .navbar-toggle {
      border: 0;
      background-color: transparent;
      transition: all 300ms ease;
      margin: 26px 15px 26px 0;
      .icon-bar {
        background-color: $white;
      }
      &:hover, &:focus {
        background-color: transparent;
      }
      &:active, &:active:focus {
        background-color: transparent
      }
    }
    .navbar-brand {
      width: 445px;
      height: 114px;
      max-width: calc(100vw - 96px);
      img {
        width: 100%;
      }
    }
  }
  .navbar-collapse {
    border: none;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    width: 100%;
    .navbar-nav {
      margin: 13px -15px;
      > li {
        > a {
          text-transform: uppercase;
          color: $white;
          font-size: rem(15);
          padding: 10px 15px;
            &:after{
                  content: '';
                left: 10px;
                right: 10px;
                  display: block;
                  height: 2px;

                  background-color: $color-primary;
                  position: absolute;
                  bottom: 0;
                  margin: auto;
                  @include transition-sm();
                transform: scaleX(0);

              }
          &:hover, &:focus {
            color: #fff;
              &:after{
                      transform: scaleX(1);
                  }
          }
          &:active, &:active:focus {
            color: darken($color-primary, 10%);
          }
        }
          &.active{
              a{
                  background-color: transparent;
                  &:after{
                      transform: scaleX(1);
                  }
              }
          }
      }
    }
  }

  @include breakpoint(sm) {
    .navbar-header {
      height: 130px;
      width: 100%;
      max-width: calc(100% - 465px);
      .navbar-brand {
        height: auto;
        max-width: 430px;
        width: 100%;
      }
    }
    .navbar-collapse {
      background: none;
      float: right;
      height: 130px !important;
      position: inherit;
      width: auto;
      .navbar-nav {
        > li {
          > a {
            padding: 10px;
          }
        }
      }
    }
  }

  @include breakpoint(md) {
    .navbar-header {
        width: auto;
      .navbar-brand {
        width: 445px;
      }
    }
  }
}

.navbar-toggle {
 @include transition-sm();
 border: none;
 border-radius: 0;
 padding: 23px;
 .icon-bar {
   @include transition-sm();
   background-color: $grey-light;
   width: 18px;
   transform: rotate(45deg);
   &:nth-child(2) {
     transform: translateY(6px) rotate(-45deg);
   }
   &:last-child {
     transform: translateY(-6px) rotate(0);
     opacity: 0;
   }
 }
 &.collapsed .icon-bar {
   transform: rotate(0);
   &:first-child {
     transform: translateY(0) rotate(0);
   }
   &:last-child {
     opacity: 1;
   }
 }
}
