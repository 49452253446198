#rental-room {
	h2 {
		margin-bottom: 20px;
	}
	.link-action {
		margin: 30px 0;
	}
	.quote-widget {
		margin-top: 20px;
		margin-bottom: 0;
	}
	@include breakpoint(sm) {
		h2 {
			margin-bottom: 70px;
		}
		.quote-widget {
			margin-top: 40px;
		}
	}
}

#rental-info {
	@include breakpoint(sm) {
		.grey-card {
			min-height: 420px;
		}
	}
	@include breakpoint(md) {
		.grey-card {
			min-height: 355px;
		}
	}
}