/* BUTTONS
===========================
Important: See _mixins for time variables.
 */

a, .btn, button {
    @include transition-md();
    &, &:hover, &:active, &:focus {
      text-decoration: none;
      outline: none !important;
    }
}

.btn {
  p {
    margin: 0;
  }
}

.btn-default {
    font-family: $font-title;
    font-size: 14px;
    padding: 12px 38px;
    border-radius: 0;
    text-transform: uppercase;
}

@mixin btn-filled ($color) {
    @extend .btn-default;
    background-color: $color;
    border-color: $color;
    color: $white;
    &:hover, &:focus {
        background-color: lighten($color, 10%);
        border-color: lighten($color, 10%);
        box-shadow: 0 4px 30px -5px lighten($color, 10%);
        color: $white;
    }
    &:active, &:active:focus, &:active:hover {
        background-color: darken($color, 10%);
        border-color: darken($color, 10%);
        box-shadow: 0 4px 15px -5px darken($color, 10%);
        color: $white;
    }
}

@mixin btn-outline ($color) {
    @extend .btn-default;
    background-color: transparent;
    border-color: $color;
    color: $color;
    &:hover, &:focus {
        background-color: $color;
        border-color: $color;
        box-shadow: 0 4px 30px -5px lighten($color, 10%);
        color: $white;
    }
    &:active, &:active:focus, &:active:hover {
        background-color: darken($color, 10%);
        border-color: darken($color, 10%);
        box-shadow: 0 4px 15px -5px darken($color, 10%);
        color: $white;
    }
}

.btn-primary {
    @include btn-filled($color-primary);
    &.btn-outline {
        @include btn-outline($color-primary);
    }
}

.btn-black {
    @include btn-filled($black);
    &.btn-outline {
        @include btn-outline($black);
    }
}

.btn-full {
    width: 100%;
}

.btn-full-mobile {
    width: 100%;
    @include breakpoint(sm) {
        width: initial;
    }
}

.link-action {
    font-family: $font-title;
    font-weight: 700;
    text-transform: uppercase;
    color: $black;
    display: flex;
    i {
        color: $color-primary;
        margin-right: 20px;
        font-size: rem(22);
        line-height: rem(22);
        vertical-align: middle;
        transition: transform 500ms ease;
    }
    span {
        line-height: rem(22);
    }
    &:hover, &:focus {
        text-decoration: none;
        color: $black;
        i {
            transform: translateX(10px);
        }
    }
}

.btn-filters {
  @extend .btn-black.btn-outline;
  &.active {
    @extend .btn-primary;
  }
}
