/* FOOTER */

.footer-default {
	.footer-curved {
	    line-height: 0;
    	margin-bottom: -1px;
		svg {
			width: 100%;
		}
	}
	.footer-content {
		background-color: $black;
		color: $white;
		text-align: center;
		padding: 40px 30px 60px;
	}
	.footer-logo {
		img {
			max-width: 100%;
		}
	}
	.footer-social {
		margin-top: 20px;
		margin-bottom: 20px;
		a {
			color: $white;
			font-size: 28px;
			margin: 0 10px;
			&:hover {
				color: $color-primary;
			}
		}
	}
	.footer-copyright {
		text-transform: uppercase;
		font-size: 12px;
	}
  #nixa-link {
    text-transform: uppercase;
		font-size: 12px;
    color: #fff;
    display: block;
    &:hover, &:focus, &:active {
      color: lighten($color-primary, 10%);
      letter-spacing: 2px;
    }
  }
	@include breakpoint(sm) {
		.footer-content {
			padding: 50px 30px 100px;
		}
	}
}

.with-grey-light-footer-curve {
  .footer-default {
    .footer-curved {
      background-color: $grey-light;
    }
  }
}
