/* FORMS
=========================== */
.form-control {
  font-size: 16px; //ios form autozoom fix
  @include breakpoint(sm) {
    font-size: 14px;
  }
}

.outline-form {
  .outline-form-section-title {
    margin-bottom: 35px;
  }
  .form-group {
    position: relative;
    label {
      font-size: rem(14);
      text-transform: uppercase;
      font-weight: 700;
    }
    input[type=text], .emailinput {
      background: none;
      border-radius: 0;
      padding: 14px 0;
      border: none;
      border-bottom: 2px solid $black;
      position: relative;
      box-shadow: none;
      color: $black;
      height: 45px;
      margin-bottom: 30px;
      display: inline-block;
      width: 100%;
      &::before {
        content: " ";
      }
      &:focus {
        box-shadow: none;
        border-color: $color-primary;
      }
      @include breakpoint(sm) {
        margin-bottom: 50px;
      }
    }
    textarea {
      @extend .emailinput;
      border: 2px solid $black;
      padding: 6px 12px;
      height: auto;
    }
    .help-block {
      position: absolute;
      bottom: 5px;
      &[id^="hint"] {
        bottom: 0;
        color: lighten($black, 40%);
      }
    }
    &.has-error {
      label {
        color: $black;
      }
    }
    &#div_id_period, &#div_id_description {
      margin-bottom: 30px;
      .help-block {
        position: static;
      }
      .form-control {
        margin-bottom: 10px;
      }
    }
  }
  @include breakpoint(sm) {
    .form-group {
      .help-block {
        bottom: 23px;
        &[id^="hint"] {
          bottom: 15px;
        }
      }
    }
  }
}
