.slide-down {
    animation: slideDown 1000ms ease;
    animation-fill-mode: forwards;
}


@keyframes slideDown {
    0%   {
    	transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
