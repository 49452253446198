/* HERO
=========================== */
.hero {
  background-color: $black;
  height: 341px;
  position: relative;
  @include breakpoint(sm) {
    height: 500px;
  }
  @include breakpoint(md) {
    height: 625px;
  }
  .fullwidth-bg{
       -webkit-box-shadow: inset 0px 200px 192px -107px rgba(0,0,0,0.8);
  -moz-box-shadow: inset 0px 200px 192px -107px rgba(0,0,0,0.8);
  box-shadow: inset 0px 200px 192px -107px rgba(0,0,0,0.8);
  }
}



#home-hero {
  background-color: #000;
  padding-top: 85px;
  #home-hero-container {
    position: relative;
    z-index: 0;
    height: auto;
    min-height: 341px;
    padding-top: 0;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 0;
    margin-top: 0;
    text-align: center;
    background-size: cover;
    background-position: center center;
  }
  #hero-piano-mask {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-position: center center;
    background-size: cover;
    z-index: 1;
    display: none;
  }
  #home-title {
    color: $white !important;
    margin-top: 35px;
    margin-bottom: 150px;
    padding-top: 0px;
    display: inline-block;
    text-align: left;
    small {
      color: $white;
    }
    .last {
      margin-left: 140px;
    }
  }
  #home-hero-arrow {
    font-size: 40px;
    color: $white;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0);
    width: 26px;
    margin: auto;
    transition: all 500ms ease;
    display: none;
    &:hover, &:focus {
      text-shadow: 0 2px 10px rgba(255, 255, 255, 0.8);
    }
    &:active, &:active:focus {
      color: rgba(220, 220, 220, 1);
      text-shadow: 0 1px 5px rgba(220, 220, 220, 0.5);
    }
  }
  .btn {
    width: 100%;
    margin-bottom: 20px;
  }
  @include breakpoint(xs) {
    #home-title {
      .last {
        margin-left: 175px;
      }
    }
  }
  @include breakpoint(sm) {
    padding-top: 0;
    #home-hero-container {
      padding-top: 0;
      padding-bottom: 0;
      min-height: 580px;
      height: 760px;
      margin-top: 0;
    }
    #hero-piano-mask {
      display: block;
    }
    #home-title {
      position: absolute;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      bottom: 36%;
      margin-top: 0;
      margin-bottom: 0;
      .last {
        margin-left: 390px;
      }
    }
    .btn {
      position: absolute;
      left: 50%;
      bottom: 135px;
      transform: translateX(-50%);
      z-index: 2;
      width: auto;
      margin-bottom: 0;
    }
    #home-hero-arrow {
      position: absolute;
      left: 50%;
      bottom: 43px;
      transform: translateX(-50%);
      z-index: 2;
      margin: 0;
      width: auto;
      display: block;
    }
  }
  @include breakpoint(md) {
    #home-hero-container {
      height: 970px;
    }
    #home-title {
      bottom: 40%;
    }
    .btn {
      bottom: 175px;
    }
    #home-hero-arrow {
      bottom: 50px;
    }
  }
  @include breakpoint(lg) {
    #home-title {
      bottom: 37%;
    }
  }
  @include breakpoint(xl) {
    #home-hero-container {
      max-width: 1830px;
      margin-left: auto;
      margin-right: auto;
      box-shadow: inset 0px 110px 180px 80px #000;
    }
  }
}

#contact-map-hero {
  position: relative;
  #contact-map-hero-shadow {
    -webkit-box-shadow: inset 0px 200px 192px -107px rgba(0,0,0,0.8);
    -moz-box-shadow: inset 0px 200px 192px -107px rgba(0,0,0,0.8);
    box-shadow: inset 0px 200px 192px -107px rgba(0,0,0,0.8);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    pointer-events: none;
  }
}
