/* MIXINS
=========================== */

$time-sm: 300ms;
$time-md: 600ms;
$time-lg: 900ms;

@function rem($pixels, $context: $baseFontSize) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1rem;
}

@mixin transition-sm($type:all, $ease:ease) {
    @include transition-fix();
    -webkit-transition: $type $time-sm $ease;
    -moz-transition: $type $time-sm $ease;
    -o-transition: $type $time-sm $ease;
    transition: $type $time-sm $ease;
}

@mixin transition-md($type:all, $ease:ease) {
    @include transition-fix();
    -webkit-transition: $type $time-md $ease;
    -moz-transition: $type $time-md $ease;
    -o-transition: $type $time-md $ease;
    transition: $type $time-md $ease;
}

@mixin transition-lg($type:all, $ease:ease) {
    @include transition-fix();
    -webkit-transition: $type $time-lg $ease;
    -moz-transition: $type $time-lg $ease;
    -o-transition: $type $time-lg $ease;
    transition: $type $time-lg $ease;
}

@mixin transition-fix() {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0)
}

@mixin gradient-linear($baseColor, $secondColor, $basePercentage, $secondPercentage) {
  background: $baseColor; /* Old browsers */
  background: -moz-linear-gradient(top, $baseColor  $basePercentage, $secondColor $secondPercentage); /* FF3.6+ */
  background: -webkit-gradient(linear, top, left bottom, color-stop( $basePercentage, $baseColor), color-stop($secondPercentage, $secondColor)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, $baseColor  $basePercentage, $secondColor $secondPercentage); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, $baseColor  $basePercentage, $secondColor $secondPercentage); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, $baseColor  $basePercentage, $secondColor $secondPercentage); /* IE10+ */
  background: linear-gradient(to bottom, $baseColor  $basePercentage, $secondColor $secondPercentage); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$baseColor, endColorstr=$secondColor, GradientType=0); /* IE6-9 */
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

@mixin box-shadow($top, $left, $blur, $margin, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $margin $color;
    -moz-box-shadow:inset $top $left $blur $margin $color;
    box-shadow:inset $top $left $blur $margin $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $margin $color;
    -moz-box-shadow: $top $left $blur $margin $color;
    box-shadow: $top $left $blur $margin $color;
  }
}

@mixin cover-image() {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

@mixin fa-icon ($icon) {
  content: $icon;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
}

@mixin placeholder-color($color){
  ::-webkit-input-placeholder {
    color:  $color;
  }
  :-moz-placeholder {
     color: $color;
  }
  ::-moz-placeholder {
     color:  $color;
  }
  :-ms-input-placeholder {
     color: $color;
  }
}

@mixin list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list-reset {
  @include list-reset;
}

.list-inline {
  @include list-reset;
  margin: 0;
  li {
    padding: 0;
    display: inline-block;
  }
}

.list-flex {
  @include list-reset;
  display: flex;
  justify-content: space-evenly;
  @supports (-ms-ime-align:auto) {
    //edge don't recognise space-envenly.
     justify-content: space-around;
    li {
      width: 50%;
    }
  }
}

.clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.img-bg {
  @include cover-image();
  width: 900px;
  height: 200px;
}

