/* MODALS
=========================== */
.modal-video {
  .modal-content {
    border-radius: 0;
    background-color: $black;
    .modal-header {
      border: none;
      .modal-title {
        color: $white;
        font-size: rem(16);
        p {
          display: inline-block;
        }
      }
      .close {
        color: $white;
        text-shadow: none;
        opacity: 1;
        transition: opacity 500ms ease;
        &:hover, &:focus {
          opacity: .8;
        }
        &:active, &:active:focus {
          opacity: .6;
        }
      }
    }
    .modal-body {
      padding: 0;
      .video-embed {
        position:relative;
        padding-bottom:56.25%;
        padding-top:30px;
        height:0;
        overflow:hidden;
        iframe, object, embed {
          position:absolute;
          top:0;
          left:0;
          width:100%;
          height:100%;
        }
      }
    }
  }
}

.modal-xl {
  @include breakpoint(sm) {
    width: 650px;
  }
  @include breakpoint(md) {
    width: 80%;
  }
  @include breakpoint(lg) {
    width: 70%;
  }
}
