/* FONTS
===========================
Important:
- Define default font families and font styles here.
- Design titles only using .d1, .d2... Not h1, h2...
- Use EM font sizes and adjust responsive by setting body font size.
- Default body font size is 16px.
*/

$font-title: 'Lato', sans-serif;
$font-content: 'Open Sans', sans-serif;
$font-fancy: 'lust', serif;

$baseFontSize : 14;

html, body {
    font-family: $font-content;
    color: $black;
    font-size: $baseFontSize - 2 * 1px;
    letter-spacing: 0.05em;
    line-height: 1.8em;
    transition: opacity ease 1000ms;
    .wf-loading & {
      opacity: 0;
    }
    @include breakpoint(lg) {
      font-size: $baseFontSize * 1px;
    }
}

a{
    color: $color-primary;
    &:hover{
        color: darken($color-primary, 10%);
    }
}

h1, h2, h3, h4, .d1, .d2, .d3, .d4 {
	font-family: $font-fancy;
	text-transform: uppercase;
    letter-spacing: initial;
    &:first-child {
    	margin-top: 0;
    }
}

h5, h6, .d5, .d6 {
    font-family: $font-title;
    &:first-child {
    	margin-top: 0;
    }
}

.d1 {
	font-size: rem(40);
    line-height: rem(40);
	small {
		font-size: rem(23);
	}
  @include breakpoint(xs) {
    font-size: rem(65);
      line-height: rem(65);
    small {
      font-size: rem(30);
    }
  }
  @include breakpoint(sm) {
    font-size: rem(75);
      line-height: rem(70);
    small {
      font-size: rem(40);
    }
  }
}

.d2 {
	font-size: rem(30);
    line-height: rem(40);
    @include breakpoint(sm) {
		  font-size: rem(43);
    	line-height: rem(47);
    }
    @include breakpoint(md) {
      font-size: rem(50);
      line-height: rem(55);
    }
}

.d3 {
	font-size: rem(40);
}

.d4 {
	font-size: rem(30);
}

.d5 {
	font-weight: 900;
	font-size: rem(24);
    line-height: rem(28);
    text-transform: none;
    margin-bottom: 25px;
}

.d5-font-content {
  @extend .d5;
  text-transform: none;
  font-family: $font-content;
}

p:last-child {
  &:not([class*='margin-']) {
    margin-bottom: 0;
  }
}

* { outline: none }

.text-alt { font-family: $font-title }
.text-fancy { font-family: $font-fancy }

.text-uppercase { text-transform: uppercase }
.text-capitalize { text-transform: capitalize }

.text-thin { font-weight: 100 }
.text-normal { font-weight: 400 }
.text-bold { font-weight: 700 }
.text-extrabold { font-weight: 900 }

.link-action {
	font-family: $font-title;
	font-weight: 700;
	text-transform: uppercase;
	color: $black;
	i {
		color: $color-primary;
		margin-right: 20px;
		font-size: rem(22);
		line-height: rem(22);
		vertical-align: middle;
		transition: transform 500ms ease;
	}
	span {
		line-height: rem(22);
	}
	&:hover, &:focus {
		text-decoration: none;
		color: $black;
		i {
			transform: translateX(10px);
		}
	}
}

.rounded-check-list {
  ul {
    padding-left: 0;
    list-style-type: none;
  }
  padding-left: 0;
  list-style-type: none;
  li {
    position: relative;
    padding-left: 35px;
    margin-bottom: 10px;
    &::before {
      content: "\f375";
      display: inline-block;
      font-family: "Ionicons";
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      text-rendering: auto;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: $color-primary;
      font-size: 20px;
      position: absolute;
      left: 0;
      top: 4px;
    }
  }
}

.sm-italic {
  font-size: rem(12);
  font-style: italic;
  line-height: 1.8;
}
