#history-mission, #history-history {
  //changed the margins to fit the mockups (sm reduces 5 px, md reduces 15px)
  .margin-bottom-sm {
    margin-bottom: 20px;
  }

  .p-margin-bottom-sm {
    p {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  @include breakpoint(md) {
    .margin-bottom-md {
      margin-bottom: 45px;
    }
  }
}

#history-mission-img-side {
  max-height: 350px;
}
