/* 404 */
.error-hero {
    min-height: 450px;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .error-text-container {
        position: relative;
        z-index: 2;
        max-width: 100%;
        padding: 15px;
        align-self: center;
        p {
            max-width: 400px;
        }
    }
    .fullwidth-bg {
        z-index: 1;
    }
}
