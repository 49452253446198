#gallery-images {
  max-width: 100%;
  width: 300px;
  margin: auto;
  a {
    padding-bottom: 20px;
    max-width: 100%;
    transition: opacity 500ms ease;
    opacity: 1;
    img {
      max-width: 100%;
    }
    &:hover, &:focus {
      opacity: .8;
    }
    &:active, &:active:focus {
      opacity: 1;
    }
  }
  @include breakpoint(sm) {
    width: auto;
    margin: 0;
    a {
      padding-bottom: 30px;
    }
  }
}

@include breakpoint(sm) {
  .grid-sizer, .grid-item {
    width: calc(33.33% - 20px);
  }

  .gutter-sizer {
    width: 30px;
  }
}

#gallery-filters {
  button {
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
  @include breakpoint(sm) {
    button {
      width: auto;
      &:not(:last-child) {
        margin-bottom: 3px;
      }
    }
  }
  @include breakpoint(md) {
    button {
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }
}

#gallery-gallery {
  //margin-bottom-sm 10px larger (actually 25) to make it ft the mockup
  .margin-bottom-sm {
    margin-bottom: 35px;
  }
}
